
import {
  AnalyticFilter,
  AnalyticsState,
  AnalyticsTypes,
} from "@/store/modules/analytics/analytics.types";
import VueApexCharts from "vue-apexcharts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const analyticX = namespace("Analytics");

@Component({
  components: {
    StatsAvatar: () => import("@/components/dashboard/StatsAvatar.vue"),
    VueApexCharts,
  },
})
export default class TicketsByAssignees extends Vue {
  @analyticX.State(AnalyticsTypes.ASSIGNEE_ANALYTICS)
  public assigneeAnalytics!: AnalyticsState;

  @analyticX.Action(AnalyticsTypes.LOAD_ASSIGNEE_ANALYTICS)
  public loadAssigneeStats!: (filter: AnalyticFilter) => void;

  get series(): unknown {
    return [
      {
        name: "Tickets",
        data: this.assigneeAnalytics.analytics.map((a) => ({
          x: a.name,
          y: a.count,
        })),
      },
    ];
  }

  public options: unknown = {
    plotOptions: {},
    colors: ["#FFAC62"],
    grid: {
      show: true,
      borderColor: "#EEEEEE",
      column: {
        opacity: 1,
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      title: {
        text: "Assignees",
        offsetY: 90,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: ["#757575"],
        },
        formatter: (value: number) => value.toFixed(0),
      },
      min: 0,
      title: {
        text: "Activities",
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  };

  @Prop({ default: undefined }) public start?: string;
  @Prop({ default: undefined }) public end?: string;

  filterChanged(value: { forced?: boolean }): void {
    this.loadAssigneeStats({
      start: this.start,
      end: this.end,
      force: value.forced,
    });
  }

  mounted(): void {
    this.filterChanged({});
  }
}
